import { Alert, Button } from "react-bootstrap";
import Highlighter from "../../../components/highlighter/Highlighter";
import MultiNoteCallNotes from "./MultiNoteCallNotes";
import AdvanceMultiCallNotes from "./AdvanceMultiCallNotes";
import React from "react";
import useAgencySettings from "../../../hooks/useAgencySettings";

// Call Notes come in many different flavors, any is most practical for now
interface ICallNotes {
    callNotes?: any;
    isTruncated?: boolean;
    setCurrentTab?: () => void | null;
}

const BaseCallNotes = ({ callNotes, isTruncated = false, setCurrentTab = () => null }: ICallNotes) => {
    const { callText } = useAgencySettings();
    if (!callNotes || !callNotes.length) {
        return <Alert variant="warning">No {callText} Notes to view.</Alert>;
    }

    const agencyId = callNotes[0]?.agency_id;
    let notes = callNotes;
    let component;
    // special case for overview on an event pages
    if (isTruncated) {
        notes = callNotes.slice(0, 5);
    }

    switch (agencyId) {
        // This also targets TX multi agency when waco, but not abilene
        case "tx-wcpd1":
        case "nv-rnpd1":
            component = <AdvanceMultiCallNotes callNotes={notes} isTruncated={isTruncated} setCurrentTab={setCurrentTab} />;
            break;
        // Aurora walmart will get targeted here because they use co-arpd1 call notes
        case "co-acso1":
        case "co-arpd1":
        case "co-jfcm1":
        case "co-chpd1":
        case "co-ewpd1":
        case "wi-fipd1":
        case "fl-tapd1":
            component = (
                <MultiNoteCallNotes callNotes={notes} isTruncated={isTruncated} setCurrentTab={setCurrentTab} sortBy="sequence_id" />
            );
            break;
        case "co-dcso1":
        case "ca-lmpd1":
        case "ca-bhpd1":
        case "ca-htpd1":
        case "co-crpd1":
        case "ny-mtap1":
            component = <MultiNoteCallNotes callNotes={notes} isTruncated={isTruncated} setCurrentTab={setCurrentTab} />;
            break;
        // Greensboro walmart will get targeted here because they use nc-gbpd1
        default:
            component =
                !!notes && notes.length && Array.isArray(notes) ? (
                    <TextBlobCallNotes callNotes={notes} isTruncated={isTruncated} setCurrentTab={setCurrentTab} />
                ) : null;
            break;
    }
    return component;
};

// Default, barebones call notes implementation
const TextBlobCallNotes = ({ callNotes, isTruncated = false, setCurrentTab = () => null }: ICallNotes) => {
    const [overTruncateLimit, setOverTruncateLimit] = React.useState(false);
    return (
        <>
            {callNotes.map((note: any, index: number) => {
                const noteBody = (note?.body || "") as string;
                if (noteBody.length > 360 && !overTruncateLimit) {
                    setOverTruncateLimit(true);
                }
                return (
                    <p className="mb-2" key={index} style={{ whiteSpace: "pre-line" }}>
                        {noteBody.length === 0 ? (
                            <span>No Call Note Text</span>
                        ) : (
                            <Highlighter>
                                {isTruncated ? `${noteBody.slice(0, 360)}${noteBody.length > 360 ? "..." : ""}` : noteBody || ""}
                            </Highlighter>
                        )}
                    </p>
                );
            })}
            {isTruncated && (callNotes.length === 5 || overTruncateLimit) && (
                <div>
                    <Button
                        className="px-0 pendo_blob_call_notes_see_more"
                        variant="link"
                        onClick={setCurrentTab}
                        data-testid="call-notes-view-more-button"
                    >
                        View More
                    </Button>
                </div>
            )}
        </>
    );
};

export default BaseCallNotes;
